import React, { useState, useRef, useEffect } from "react";
import {
  doc,
  addDoc,
  collection,
  updateDoc,
  setDoc,
  QuerySnapshot,
} from "firebase/firestore";
import db from "../firebase";

import {
  GoogleFormProvider,
  useGoogleForm,
  useShortAnswerInput,
} from "react-google-forms-hooks";
import { useStopwatch } from "react-timer-hook";

import femRuqDB from "./seeds/femRuqDB";
import femLuqDB from "./seeds/femLuqDB";
import femPelvicDB from "./seeds/femPelvicDB";

import "./FemLanding.css";
import FemQuestionScreen from "./FemQuestionScreen";
import FemPreSurvey from "./FemPreSurvey";
import FemAgreement from "./FemAgreement";
import FemPostSurvey from "./FemPostSurvey";
import FemDone from "./FemDone";

function FemLanding() {
  const [isFemLoggedin, setIsFemLoggedIn] = useState(false);
  const [isFemConsentDone, setIsFemConsentDone] = useState(false);

  const [isFemPreSurveyDone, setIsFemPreSurveyDone] = useState(null);
  const [allQuestionsDone, setAllQuestionsDone] = useState(false);
  const [isFemPostSurveyDone, setIsFemPostSurveyDone] = useState(null);

  const femSignupUserIdRef = useRef(null);
  const femLoginUserIdRef = useRef(null);

  const [haveFemAccount, setHaveFemAccount] = useState(true);
  const [currentAccountInfo, setCurrentAccountInfo] = useState({
    username: "",
    qSet: [],
    currentQ: "",
    pastQuestionSet: [],
    totalScore: 0,
  });
  const femLoginSignupToggle = () => {
    setHaveFemAccount(!haveFemAccount);
    femSignupUserIdRef.current.value = "";
  };

  // Stopwatch
  const { seconds, minutes, hours, days, isRunning, start, pause, reset } =
    useStopwatch({ autoStart: false }); //change to auto later
  // Stopwatch end

  const shuffleQuestion = () => {
    const randomizedQs = [];
    // Get minimum number of questions to have
    const smallestQlength = Math.min(
      femRuqDB.length,
      femLuqDB.length,
      femPelvicDB.length
    );
    for (let s = 0; s < smallestQlength; s++) {
      const randomNumGenRUQ = Math.floor(Math.random() * femRuqDB.length);
      const randomNumGenLUQ = Math.floor(Math.random() * femLuqDB.length);
      const randomNumGenPelvic = Math.floor(Math.random() * femPelvicDB.length);
      randomizedQs.push({
        RUQquestion: femRuqDB[randomNumGenRUQ],
        LUQquestion: femLuqDB[randomNumGenLUQ],
        PELquestion: femPelvicDB[randomNumGenPelvic],
      });
    }
    return randomizedQs;
    // setQuestionSet(randomizedQs);
  };

  const femSignup = async (e) => {
    e.preventDefault();

    // get date and time
    let currentTimestamp = Date.now();
    let date = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(currentTimestamp);

    // Collect all user info
    const signupAllFemUserIDs = [];
    await db
      .collection("femusers")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (femuserDoc) => {
          signupAllFemUserIDs.push(femuserDoc.data().username);
        });
        if (femSignupUserIdRef.current.value.length < 4) {
          window.alert("User ID should be longer than 4 letters.");
        } else if (
          femSignupUserIdRef.current.value.toLowerCase() === "asdf" ||
          femSignupUserIdRef.current.value.toLowerCase() === "qwer" ||
          femSignupUserIdRef.current.value.toLowerCase() === "abcd" ||
          femSignupUserIdRef.current.value.toLowerCase() === "zxcv" ||
          femSignupUserIdRef.current.value === "1234"
        ) {
          window.alert("Unable User ID. Try different User ID.");
        } else if (
          signupAllFemUserIDs.includes(femSignupUserIdRef.current.value)
        ) {
          window.alert("This User ID is taken.");
        } else {
          // window.alert("YEahe!");
          setDoc(doc(db, "femusers", femSignupUserIdRef.current.value), {
            username: femSignupUserIdRef.current.value,
            qSet: shuffleQuestion(),
            currentQ: 1,
            pastQuestionSet: [],
            totalScore: 0,
            consentDone: null,
            preSurveyDone: null,
            joined: date,
            loginHistory: [date],
            postSurveyDone: null,
          });
          setCurrentAccountInfo({
            username: femSignupUserIdRef.current.value,
            qSet: shuffleQuestion(),
            currentQ: 1,
            pastQuestionSet: [],
            totalScore: 0,
            consentDone: null,
            preSurveyDone: null,
            joined: date,
            loginHistory: [date],
            postSurveyDone: null,
          });
          setIsFemLoggedIn(true);
          setAllQuestionsDone(false);
          // setIsFemPreSurveyDone(null);
          // let shuffledQs = shuffleQuestion(sfaSeed);
          // setDoc(doc(db, "sfausers", femSignupUserIdRef.current.value), {
          //   userId: femSignupUserIdRef.current.value,
          //   joined: date,
          //   lastLogin: date,
          //   loginHistory: [date],
          //   remainedQs: shuffledQs,
          //   currentQ: shuffledQs[0],
          //   pastQs: [],
          //   donePreSurvey: false,
          //   donePostSurvey: false,
          //   doneAgreement: false,
          //   correctQCount: 0,
          //   avgScore: 0,
          // });
          // // local state update
          // setAccountInfo({
          //   userId: femSignupUserIdRef.current.value,
          //   joined: date,
          //   lastLogin: date,
          //   loginHistory: [date],
          //   remainedQs: shuffledQs,
          //   currentQ: shuffledQs[0],
          //   pastQs: [],
          //   donePreSurvey: false,
          //   donePostSurvey: false,
          //   doneAgreement: false,
          //   correctQCount: 0,
          //   avgScore: 0,
          // });
          // setIsDoneAgreement(false);
          // setForm(preSurveyForm);
          // setIsDonePreSurvey(false);
          // setIsDonePostSurvey(false);
          // setCurrentQ(shuffledQs[0]);
          // setRemainedQs(shuffledQs);
          // setPastQs([]);
          // setcorrectQCount(0);
          // setcorrectQCount(0);
          // setAvgScore(0);

          // setIsLoggedin(true);
        }
      });

    // scroll to top
    window.scrollTo(0, 0);
  };
  // Signup end
  let currentTimestamp = Date.now();
  let date = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }).format(currentTimestamp);

  // Login start
  const femLogin = (e) => {
    // Get current time
    let currentTimestamp = Date.now();
    let date = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(currentTimestamp);

    const collectAllUsers = [];
    db.collection("femusers")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (femuserDoc) => {
          collectAllUsers.push(femuserDoc.data());
        });
        const matchedUser = collectAllUsers.find(
          (user) => user.username === femLoginUserIdRef.current.value
        );
        if (matchedUser) {
          setCurrentAccountInfo({
            username: femLoginUserIdRef.current.value,
            qSet: matchedUser.qSet,
            currentQ: matchedUser.currentQ,
            pastQuestionSet: matchedUser.pastQuestionSet,
            totalScore: matchedUser.totalScore,
            consentDone: matchedUser.consentDone,
            preSurveyDone: matchedUser.preSurveyDone,
            joined: matchedUser.joined,
            loginHistory: matchedUser.loginHistory,
            postSurveyDone: matchedUser.postSurveyDone,
          });
          // window.alert("login success!");
          setIsFemLoggedIn(true);
          setIsFemConsentDone(matchedUser.consentDone);
          setAllQuestionsDone(
            matchedUser.pastQuestionSet.length === matchedUser.qSet.length
              ? true
              : false
          );
          console.log(
            matchedUser.pastQuestionSet.length === matchedUser.qSet.length
          );
          setIsFemPreSurveyDone(matchedUser.preSurveyDone);
          setIsFemPostSurveyDone(matchedUser.postSurveyDone);
        } else {
          window.alert("User ID not found.");
        }
      });
    // scroll to top
    window.scrollTo(0, 0);
  };

  // FEM agreement
  const [isConsentToggleOn, setIsConsentToggleOn] = useState(false);
  const toggleFemConsent = (e) => {
    if (document.querySelector('input#femConsent[type="checkbox"]').checked) {
      setIsConsentToggleOn(true);
    } else setIsConsentToggleOn(false);
  };
  const clickFemConsentSubmit = () => {
    // update FB db
    const updateConsentInfoFb = async () => {
      const firebaseUsernameMatch = doc(
        db,
        "femusers",
        currentAccountInfo.username
      );
      await updateDoc(firebaseUsernameMatch, {
        consentDone: true,
      }).catch((err) => {
        console.err("Cannot proceed", err);
      });
    };
    updateConsentInfoFb();
    setIsFemConsentDone(true);
    window.scrollTo(0, 0);
  };
  // FEM agreement end

  const updatePreSurveyProgress = (newState) => {
    setIsFemPreSurveyDone(newState);
    setCurrentAccountInfo({ ...currentAccountInfo, preSurveyDone: newState });
    // Update DB
    const allFemUsers = [];
    db.collection("femusers")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (femuserDoc) => {
          allFemUsers.push(femuserDoc.data());
        });
        const matchedUser = allFemUsers.find(
          (user) => user.username === currentAccountInfo.username
        );
        if (matchedUser) {
          // update firebase db
          const updateFB = async () => {
            await updateDoc(doc(db, "femusers", matchedUser.username), {
              // lastLogin: date,
              preSurveyDone: newState,
              // loginHistory: [...matchedUser.loginHistory, date]
            });
          };
          updateFB();
        } else {
          window.alert("error");
        }
      });
  };

  const updatePostSurveyProgress = (newState) => {
    setIsFemPostSurveyDone(newState);
    setCurrentAccountInfo({ ...currentAccountInfo, postSurveyDone: newState });
    // Update DB
    const allFemUsers = [];
    db.collection("femusers")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (femuserDoc) => {
          allFemUsers.push(femuserDoc.data());
        });
        const matchedUser = allFemUsers.find(
          (user) => user.username === currentAccountInfo.username
        );
        if (matchedUser) {
          // update firebase db
          const updateFB = async () => {
            await updateDoc(doc(db, "femusers", matchedUser.username), {
              // lastLogin: date,
              postSurveyDone: newState,
              // loginHistory: [...matchedUser.loginHistory, date]
            });
          };
          updateFB();
        } else {
          window.alert("error");
        }
      });
  };

  console.log("currentAccountInfo.qSet", currentAccountInfo.qSet);
  const updateQuestionProgress = (newState) => {
    setAllQuestionsDone(newState);
  };

  return (
    <>
      {isFemLoggedin ? (
        <div className="femContentWrapper">
          {/* Consent */}
          {isFemConsentDone !== true ? (
            <div>
              <FemAgreement />
              <div className="femAgreement__consentWrapper">
                <input
                  type="checkbox"
                  id="femConsent"
                  name="femConsent"
                  onClick={toggleFemConsent}
                />
                <label>
                  I have read this consent describing the project and the
                  research study being performed and how the information
                  collected will be used. I agree to this.
                </label>
                <button
                  className="ub_solidPurple"
                  disabled={isConsentToggleOn ? false : true}
                  onClick={clickFemConsentSubmit}
                >
                  Agree
                </button>
              </div>
            </div>
          ) : null}

          {/* Pre Survey */}
          {isFemConsentDone && isFemPreSurveyDone === null ? (
            <FemPreSurvey
              accountInfo={{
                username: currentAccountInfo.username,
              }}
              updatePreSurveyProgress={updatePreSurveyProgress}
            />
          ) : null}

          {/* Questions */}
          {isFemConsentDone &&
          isFemPreSurveyDone !== null &&
          !allQuestionsDone ? (
            <FemQuestionScreen
              accountInfo={{
                username: currentAccountInfo.username,
                currentQ: currentAccountInfo.currentQ,
                qSet: currentAccountInfo.qSet,
                pastQuestionSet: currentAccountInfo.pastQuestionSet,
                totalScore: currentAccountInfo.totalScore,
                // preSurveyDone: currentAccountInfo.preSurveyDone,
                // joined: currentAccountInfo.joined,
                // loginHistory: currentAccountInfo.loginHistory,
                // postSurveyDone: currentAccountInfo.postSurveyDone,
              }}
              updateQuestionProgress={updateQuestionProgress}
            />
          ) : null}

          {/* Post Survey */}
          {allQuestionsDone && isFemPostSurveyDone === null ? (
            <FemPostSurvey
              accountInfo={{
                username: currentAccountInfo.username,
              }}
              updatePostSurveyProgress={updatePostSurveyProgress}
            />
          ) : null}

          {/* Thank you */}
          {isFemPostSurveyDone && isFemPostSurveyDone !== null ? (
            <FemDone
              accountInfo={{
                totalScore: currentAccountInfo.totalScore,
                pastQuestionSet: currentAccountInfo.pastQuestionSet,
              }}
            />
          ) : null}
        </div>
      ) : (
        // if the user is not logged in
        <div className="femLanding">
          {/* Top login and video section */}
          <div className="femLanding_loginVidWrapper">
            {!haveFemAccount ? (
              // Not have account
              <div className="femLanding_loginRegisterBox">
                <h1>Focused Assessment With Sonography for Trauma (FAST)</h1>
                <div className="femLanding_loginVidWrapper">
                  <div className="SfaLogin__content SfaLogin__content-login">
                    {/* <h2>Register</h2> */}
                    <div className="SfaLogin__toggleTabWrapper">
                      <p
                        className="SfaLogin_toggle femLoginRegister_toggleUnselected"
                        onClick={femLoginSignupToggle}
                      >
                        Login
                      </p>
                      <p className="SfaLogin_toggle femLoginRegister_toggleSelected">
                        Register
                      </p>
                    </div>

                    <input
                      ref={femSignupUserIdRef}
                      placeholder="User ID"
                      type="text"
                    />
                    <button
                      type="submit"
                      className="ub_solidPurple"
                      onClick={femSignup}
                    >
                      Register
                    </button>
                    {/* <p className="smallText ub_textPurple SfaLogin_toggle" onClick={loginSignupToggle}>Already have an account?</p> */}
                    <p className="smallText SfaLogin__userNameInfo">
                      * Has to be more than 4 characters.
                      <br />* Cannot be continuous characters or numbers.
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              // Have account
              <div className="femLanding_loginRegisterBox">
                <h1>Focused Assessment With Sonography for Trauma (FAST)</h1>
                <div className="femLanding_loginVidWrapper">
                  <div className="SfaLogin__content SfaSignup__content-signup">
                    {/* <h2>Login</h2> */}
                    <div className="SfaLogin__toggleTabWrapper">
                      <p className="SfaLogin_toggle femLoginRegister_toggleSelected">
                        Login
                      </p>
                      <p
                        className="SfaLogin_toggle femLoginRegister_toggleUnselected"
                        onClick={femLoginSignupToggle}
                      >
                        Register
                      </p>
                    </div>

                    <input
                      ref={femLoginUserIdRef}
                      placeholder="User ID"
                      type="text"
                    />
                    <button
                      className="ub_solidPurple"
                      onClick={function (e) {
                        femLogin();
                        reset();
                      }}
                    >
                      Login
                    </button>
                    {/* <p className="smallText ub_textPurple SfaLogin_toggle" onClick={loginSignupToggle}>Don't have an account yet?</p> */}
                  </div>
                </div>
              </div>
            )}

            <div className="femLanding_vid">
              <video
                controls
                playsInline
                autoPlay
                muted
                loop
                controlsList="nodownload"
              >
                <source
                  src={
                    "https://ultrasoundbox.s3.amazonaws.com/fem/Intro+and+Reference+video+FEM.mp4"
                  }
                />
              </video>
            </div>
          </div>
          {/* Login register video section end */}

          {/* intro paragraph start */}
          <div className="femLanding_introP">
            <p>
              This module is intended to teach users how to the Focused
              Assessment With Sonography for Trauma (FAST) exam. It is still
              under development, and as such all user data will be collected and
              analyzed to improve the module and for research purposes.
            </p>
            <p>
              The FAST (Focused Assessment with Sonography for Trauma) exam is a
              rapid bedside ultrasound examination performed by physicians as
              part of the initial evaluation of patients with suspected blunt
              trauma. The goal of the FAST exam is to identify the presence of
              free fluid, which is usually blood in the context of trauma,
              within the peritoneal cavity.
            </p>
            <p>
              We hope our module, consisting of clips of both normal and
              abnormal FAST exams, will improve your ability to perform this
              assessment.
            </p>
          </div>

          {/* creators */}
          <div className="SfaLogin__members">
            <div className="SfaLogin__memberGroup">
              <p className="SfaLogin__memberGroupTitle">Creators</p>
              <div className="SfaLogin__memberList">
                <div className="SfaLogin__member">
                  <div className="SfaLogin__memberPhoto">
                    <img
                      src="https://ultrasoundbox.s3.amazonaws.com/members/Danielle+Kerrigan.png"
                      alt=""
                    />
                  </div>
                  <p>Danielle Kerrigan, MD</p>
                </div>
                <div className="SfaLogin__member">
                  <div className="SfaLogin__memberPhoto">
                    <img
                      src="https://ultrasoundbox.s3.amazonaws.com/user-profile-images/Screen+Shot+2023-08-09+at+6.33.46+PM+(3)+(1)+copy.png"
                      alt=""
                    />
                  </div>
                  <p>Maiya Cowan, MD</p>
                </div>
              </div>
            </div>

            {/* <div className="SfaLogin__memberGroup">
              <p className="SfaLogin__memberGroupTitle">Editors</p>
              <div className="SfaLogin__memberList">
                <div className="SfaLogin__member">
                  <div className="SfaLogin__memberPhoto">
                    <img
                      src="https://ultrasoundbox.s3.amazonaws.com/members/Nhu-NguyenLe.jpeg"
                      alt=""
                    />
                  </div>
                  <p>Nhu-Nguyen Le, MD</p>
                </div>
              </div>
            </div> */}

            <div className="SfaLogin__memberGroup">
              <p className="SfaLogin__memberGroupTitle">Faculty Reviewers</p>
              <div className="SfaLogin__memberList">
                <div className="SfaLogin__member">
                  <div className="SfaLogin__memberPhoto">
                    <img
                      src="https://ultrasoundbox.s3.amazonaws.com/members/Shirley.jpeg"
                      alt=""
                    />
                  </div>
                  <p>Shirley Wu, MD</p>
                </div>
                <div className="SfaLogin__member">
                  <div className="SfaLogin__memberPhoto">
                    <img
                      src="https://ultrasoundbox.s3.amazonaws.com/members/leland-2.jpg"
                      alt=""
                    />
                  </div>
                  <p>Leland Perice, MD</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FemLanding;
