import React, { useState, useRef, useEffect } from "react";
import {
  doc,
  addDoc,
  collection,
  updateDoc,
  setDoc,
  QuerySnapshot,
} from "firebase/firestore";
import db from "../firebase";

import femRuqDB from "./seeds/femRuqDB";
import femLuqDB from "./seeds/femLuqDB";
import femPelvicDB from "./seeds/femPelvicDB";
import FemExplanationComponent from "./FemExplanationComponent";

import FemView from "./FemView";
import SfaCheatsheet from "../sfa/SfaCheatsheet";

import "./FemQuestionScreen.css";
import ScienceOutlinedIcon from "@mui/icons-material/ScienceOutlined";
import FemCheatsheet from "./FemCheatsheet";

function FemQuestionScreen(prop) {
  // get date and time
  let currentTimestamp = Date.now();
  let date = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }).format(currentTimestamp);

  const [questionSet, setQuestionSet] = useState(prop.accountInfo.qSet);
  const [currentQnum, setCurrentQnum] = useState(prop.accountInfo.currentQ);
  const [totalScore, setTotalScore] = useState(prop.accountInfo.totalScore);

  const [isCheatsheetOpen, setIsCheatsheetOpen] = useState(false);
  const [checkboxDisabled, setCheckboxDisabled] = useState(false);

  const [showFemAnswer, setShowFemAnswer] = useState(false);
  const [doneAllQuestions, setDoneAllQuestions] = useState(false);
  const [selectedFemAnswers, setSelectedFemAnswers] = useState({
    ruq: false,
    luq: false,
    pelvic: false,
    none: false,
  });
  const [checkAnswerButtonActive, setCheckAnswerButtonActive] = useState(false);
  const [pastQuestionSet, setPastQuestionSet] = useState(
    prop.accountInfo.pastQuestionSet
  );

  // Question Cheatsheet toggle
  const toggleCheatsheet = () => {
    setIsCheatsheetOpen(!isCheatsheetOpen);
  };

  const handleCheckboxChange = (event) => {
    const input = event.target;

    if (input.value === "none") {
      document.querySelector('input[id="ruq"]').checked = false;
      document.querySelector('input[id="luq"]').checked = false;
      document.querySelector('input[id="pelvic"]').checked = false;
      setSelectedFemAnswers({
        ruq: false,
        luq: false,
        pelvic: false,
        none: !selectedFemAnswers.none,
      });
    } else {
      document.querySelector('input[id="none"]').checked = false;
      setSelectedFemAnswers({
        ...selectedFemAnswers,
        [input.value]: !selectedFemAnswers[input.value],
        none: false,
      });
    }

    // Block or unblock the check the answer button
    if (
      document.querySelector('input[id="ruq"]').checked ||
      document.querySelector('input[id="luq"]').checked ||
      document.querySelector('input[id="pelvic"]').checked ||
      document.querySelector('input[id="none"]').checked
    ) {
      setCheckAnswerButtonActive(true);
    } else {
      setCheckAnswerButtonActive(false);
    }
  };
  console.log("selectedFemAnswers", selectedFemAnswers);

  // Check answer + score + show explanation
  const checkFemAnswers = () => {
    let correctNum = 0;
    if (
      questionSet[currentQnum - 1].RUQquestion.fluid === selectedFemAnswers.ruq
    )
      correctNum++;
    if (
      questionSet[currentQnum - 1].LUQquestion.fluid === selectedFemAnswers.luq
    )
      correctNum++;
    if (
      questionSet[currentQnum - 1].PELquestion.fluid ===
      selectedFemAnswers.pelvic
    )
      correctNum++;

    const updatedPastQset = [
      ...pastQuestionSet,
      {
        timeTaken: "",
        questionIndex: currentQnum - 1,
        score: correctNum,
        resultSet: {
          ruq: {
            imageUrl: questionSet[currentQnum - 1].RUQquestion.imageUrl,
            answer: questionSet[currentQnum - 1].RUQquestion.fluid,
            userChoice: selectedFemAnswers.ruq,
            isCorrect:
              questionSet[currentQnum - 1].RUQquestion.fluid ===
              selectedFemAnswers.ruq
                ? true
                : false,
          },
          luq: {
            imageUrl: questionSet[currentQnum - 1].LUQquestion.imageUrl,
            answer: questionSet[currentQnum - 1].LUQquestion.fluid,
            userChoice: selectedFemAnswers.luq,
            isCorrect:
              questionSet[currentQnum - 1].LUQquestion.fluid ===
              selectedFemAnswers.luq
                ? true
                : false,
          },
          pelvic: {
            imageUrl: questionSet[currentQnum - 1].PELquestion.imageUrl,
            answer: questionSet[currentQnum - 1].PELquestion.fluid,
            userChoice: selectedFemAnswers.pelvic,
            isCorrect:
              questionSet[currentQnum - 1].PELquestion.fluid ===
              selectedFemAnswers.pelvic
                ? true
                : false,
          },
        },
      },
    ];

    // Score
    let femScore = 0;
    const resultSet = updatedPastQset[updatedPastQset.length - 1].resultSet;
    if (resultSet.ruq.isCorrect) femScore++;
    if (resultSet.luq.isCorrect) femScore++;
    if (resultSet.pelvic.isCorrect) femScore++;
    setTotalScore(femScore + totalScore);

    setPastQuestionSet(updatedPastQset);

    // update the firebase database
    const allFemUsers = [];
    db.collection("femusers")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (femuserDoc) => {
          allFemUsers.push(femuserDoc.data());
        });
        const matchedUser = allFemUsers.find(
          (user) => user.username === prop.accountInfo.username
        );
        if (matchedUser) {
          // update firebase db
          const updateFB = async () => {
            await updateDoc(doc(db, "femusers", matchedUser.username), {
              timeStamp: date,
              currentQ: currentQnum + 1,
              pastQuestionSet: updatedPastQset,
              totalScore: femScore + totalScore,
              // loginHistory: [...matchedUser.loginHistory, date]
            });
          };
          updateFB();
        } else {
          window.alert("error");
        }
      });

    setCheckboxDisabled(true);
    setShowFemAnswer(true);
  };

  const removeAllCheck = () => {
    let inputs = document.querySelectorAll("input");
    for (let j = 0; j < inputs.length; j++) {
      if (inputs[j].checked) {
        inputs[j].checked = false;
        // console.log(`${j} ===> ${inputs[j].checked}`);
      } else {
        inputs[j].checked = false;
        // console.log(`ELSE ${j} ===> ${inputs[j].checked}`);
      }
    }
  };

  const nextQ = () => {
    setShowFemAnswer(false);
    setCheckboxDisabled(false);
    removeAllCheck();
    setSelectedFemAnswers({
      ruq: false,
      luq: false,
      pelvic: false,
      none: false,
    });
    setCurrentQnum(currentQnum + 1);
    if (pastQuestionSet.length === questionSet.length) {
      setDoneAllQuestions(true);
      prop.updateQuestionProgress(true);
    }
  };

  console.log(
    "questionSet[currentQnum - 1].RUQquestion.answerRef",
    questionSet[currentQnum - 1].RUQquestion.answerRef
  );

  return (
    <div style={{ marginTop: "100px" }}>
      {doneAllQuestions ? null : (
        <div>
          {/* Cheatsheet modal */}
          {isCheatsheetOpen ? (
            <div
              className="sfaCheatsheetBackdrop"
              onClick={() => toggleCheatsheet()}
            ></div>
          ) : null}
          {isCheatsheetOpen ? (
            <div className="sfaCheatsheetModalWrapper">
              <div className="sfaCheatsheetModal">
                {/* <h1>Reference Sheet</h1> */}
                <div
                  className="sfaCheatsheetModal-close"
                  onClick={() => toggleCheatsheet()}
                >
                  <p>CLOSE</p>
                </div>
                <FemCheatsheet />
              </div>
            </div>
          ) : null}

          {/* score */}
          <div className="femQuestionProcessWrapper">
            <div className="femQuestionProcessText">
              <p>
                Process: {currentQnum}/{questionSet.length}
              </p>
            </div>
            <div className="femQuestionProcessText">
              <p>
                Score:
                {((totalScore / (pastQuestionSet.length * 3)) * 100).toFixed(2)}
                %
              </p>
            </div>
            <div className="sfaQuestionProcessText">
              <div
                className="sfaQuestion__cheatsheetToggleBtn sfaQuestion__cheatsheetToggleBtnWithIcon"
                onClick={() => toggleCheatsheet()}
              >
                <ScienceOutlinedIcon />
                <p>Reference Sheet</p>
              </div>
            </div>
          </div>
          {/* images */}
          {questionSet.map((set, idx) => {
            if (idx === currentQnum - 1)
              return (
                <div>
                  {/* Views */}
                  <FemView
                    imgUrls={{
                      ruq: set.RUQquestion.imageUrl,
                      luq: set.LUQquestion.imageUrl,
                      pelvic: set.PELquestion.imageUrl,
                    }}
                  />
                </div>
              );
          })}

          {/* Question list start */}
          <div className="femQuestionChoiceWrapper">
            <div className="femQchoicesWrapper">
              <p className="femQuestion">Check everywhere that has fluid</p>

              <div
                className={`femQchoice ${
                  showFemAnswer &&
                  pastQuestionSet.length > 0 &&
                  pastQuestionSet[pastQuestionSet.length - 1].resultSet.ruq
                    .isCorrect
                    ? "femCorrect"
                    : ""
                } ${
                  showFemAnswer &&
                  pastQuestionSet.length > 0 &&
                  !pastQuestionSet[pastQuestionSet.length - 1].resultSet.ruq
                    .isCorrect
                    ? "femIncorrect"
                    : ""
                }`}
                key="ruq"
              >
                <input
                  type="checkbox"
                  id="ruq"
                  name=""
                  value="ruq"
                  onChange={(event) => handleCheckboxChange(event)}
                  disabled={checkboxDisabled}
                />
                <label htmlFor="ruq">1. RUQ</label>
              </div>
              <div
                key="luq"
                className={`femQchoice ${
                  showFemAnswer &&
                  pastQuestionSet.length > 0 &&
                  pastQuestionSet[pastQuestionSet.length - 1].resultSet.luq
                    .isCorrect
                    ? "femCorrect"
                    : ""
                } ${
                  showFemAnswer &&
                  pastQuestionSet.length > 0 &&
                  !pastQuestionSet[pastQuestionSet.length - 1].resultSet.luq
                    .isCorrect
                    ? "femIncorrect"
                    : ""
                }`}
              >
                <input
                  type="checkbox"
                  id="luq"
                  name=""
                  value="luq"
                  onChange={(event) => handleCheckboxChange(event)}
                  disabled={checkboxDisabled}
                />
                <label htmlFor="luq">2. LUQ</label>
              </div>
              <div
                key="pelvic"
                className={`femQchoice ${
                  showFemAnswer &&
                  pastQuestionSet.length > 0 &&
                  pastQuestionSet[pastQuestionSet.length - 1].resultSet.pelvic
                    .isCorrect
                    ? "femCorrect"
                    : ""
                } ${
                  showFemAnswer &&
                  pastQuestionSet.length > 0 &&
                  !pastQuestionSet[pastQuestionSet.length - 1].resultSet.pelvic
                    .isCorrect
                    ? "femIncorrect"
                    : ""
                }`}
              >
                <input
                  type="checkbox"
                  id="pelvic"
                  name=""
                  value="pelvic"
                  onChange={(event) => handleCheckboxChange(event)}
                  disabled={checkboxDisabled}
                />
                <label htmlFor="pelvic">3. Pelvic</label>
              </div>
              <div
                key="none"
                className="femQchoice"
                // {`femQchoice ${
                //   showFemAnswer &&
                //   pastQuestionSet.length > 0 &&
                //   !pastQuestionSet[pastQuestionSet.length].resultSet.ruq
                //     .answer &&
                //   !pastQuestionSet[pastQuestionSet.length].resultSet.luq
                //     .answer &&
                //   !pastQuestionSet[pastQuestionSet.length].resultSet.pelvic
                //     .answer &&
                //   !pastQuestionSet[pastQuestionSet.length].resultSet.ruq
                //     .userChoice &&
                //   !pastQuestionSet[pastQuestionSet.length].resultSet.luq
                //     .userChoice &&
                //   !pastQuestionSet[pastQuestionSet.length].resultSet.pelvic
                //     .userChoice
                //     ? "femCorrect"
                //     : ""
                // } ${
                //   (showFemAnswer &&
                //     pastQuestionSet.length > 0 &&
                //     !pastQuestionSet[pastQuestionSet.length].resultSet.ruq
                //       .answer &&
                //     !pastQuestionSet[pastQuestionSet.length].resultSet.luq
                //       .answer &&
                //     !pastQuestionSet[pastQuestionSet.length].resultSet.pelvic
                //       .answer) ||
                //   !pastQuestionSet[pastQuestionSet.length].resultSet.ruq
                //     .userChoice ||
                //   !pastQuestionSet[pastQuestionSet.length].resultSet.luq
                //     .userChoice ||
                //   !pastQuestionSet[pastQuestionSet.length].resultSet.pelvic
                //     .userChoice
                //     ? "femIncorrect"
                //     : ""
                // }
                // `}
              >
                <input
                  type="checkbox"
                  id="none"
                  name=""
                  value="none"
                  onChange={(event) => handleCheckboxChange(event)}
                  disabled={checkboxDisabled}
                />
                <label htmlFor="none">4. None above</label>
              </div>
            </div>
            {showFemAnswer ? (
              <div className="femAnswer">
                <div>
                  <p className="smallText" style={{ fontWeight: "bold" }}>
                    Answer:
                  </p>

                  <p className="femAnswerBold">
                    {questionSet[currentQnum - 1].RUQquestion.fluid
                      ? "RUQ "
                      : null}
                    {questionSet[currentQnum - 1].LUQquestion.fluid
                      ? "LUQ "
                      : null}
                    {questionSet[currentQnum - 1].PELquestion.fluid
                      ? "Pelvic "
                      : null}
                    {!questionSet[currentQnum - 1].RUQquestion.fluid &&
                    !questionSet[currentQnum - 1].LUQquestion.fluid &&
                    !questionSet[currentQnum - 1].PELquestion.fluid
                      ? "None above"
                      : null}
                  </p>
                </div>
                {/* <div>
                  <button
                    onClick={() => setShowFemExplanation(!showFemExplanation)}
                  >
                    {showFemExplanation
                      ? "Hide explanation"
                      : "Show explanation"}
                  </button>
                </div> */}

                <FemExplanationComponent
                  answerImg={{
                    ruq: questionSet[currentQnum - 1].RUQquestion.answerRef,
                    luq: questionSet[currentQnum - 1].LUQquestion.answerRef,
                    pelvic: questionSet[currentQnum - 1].PELquestion.answerRef,
                  }}
                />
              </div>
            ) : null}
          </div>

          {/* Button */}
          <div className="femButtonWrapper">
            {showFemAnswer ? (
              <button className="ub_solidPurple" onClick={nextQ}>
                Next
              </button>
            ) : (
              <button
                className="ub_solidPurple"
                onClick={checkFemAnswers}
                disabled={checkAnswerButtonActive ? false : true}
              >
                Check the answer
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default FemQuestionScreen;
